import {
  Text,
  Stack, 
  VStack,
  Badge,
  Code,
  Textarea,
  Divider,
  Heading,
} from '@chakra-ui/react'
import React from 'react'
import LogoSubtitle from '../LogoSubtitle'


function AppDocumentation() {

  return (
    <div style={{padding: '5% 10%'}}>
      <VStack >
      <LogoSubtitle/>
      </VStack>
      {/* Obtener antisemite score de un usuario: */}
      <Heading as='h2'  size='md' mb={'5px'}>Obtener score de antisemitismo de un usuario</Heading>
      <Stack direction='row'>
       <Code>backend.antisemitescore.org/api/getScoreAPI</Code>   <Badge colorScheme='yellow'>Get</Badge>
      </Stack>
      <Text as='i' fontSize={'11px'} textAlign={'left'}> Se requiere un token de tipo Bearer para acceder a este endpoint.</Text>
      <Text fontSize={'14px'}> 
      Este endpoint proporciona una puntuación y un comentario asociados a un usuario de Twitter, basados en sus tweets. La respuesta incluye el nombre de usuario, la puntuación y un comentario generado.
      </Text>
      <Divider  m={'15px 0'}/>
      <Text as='b' textAlign={'left'}> Parámetros de Query</Text>
      <Text fontSize={'14px'}> 
      language (opcional) :El idioma en el que se desea recibir el comentario. Por defecto, es en inglés.
      <br/>
      user: El nombre de usuario de Twitter del cual se desea obtener la puntuación y el comentario. 
      <br/>
      Opciones: en, es y pt (Inglés, Español y Portugués)
      </Text>
      <Divider  m={'15px 0'}/>
      <Stack direction='row' alignItems={'center'} mb={'10px'}> 
       <Text fontSize={'12px'}>Respuesta Exitosa:</Text>  
       <Badge fontSize={'11px'} colorScheme='green'>Código: 200</Badge>
      </Stack>
      <Textarea value={`{
            "userName": "nombre_de_usuario",
            "score": 5.7,
            "comment": "Comentario generado"
}`}
            readOnly
            size='sm'
            >
       
      </Textarea>
      <Stack direction='row' alignItems={'center'}  m={'10px 0'}> 
       <Text fontSize={'12px'}>Respuestas de Error:</Text>  
       <Badge fontSize={'11px'} colorScheme='red'>Código: 500</Badge>
      </Stack>
      <Textarea value={`{ 
        error : "Mensaje de error" 
}`}
            readOnly
            size='sm'
            >
       
      </Textarea>
     
      <Divider  m={'15px 0'}/>
       {/* Obtener antisemite score de un usuario: */}
       <Heading as='h2'  size='md' mb={'5px'}>Obtener top 10 usuarios más buscados</Heading>
      <Stack direction='row'>
       <Code>backend.antisemitescore.org/api/topSearchedUsers</Code>   <Badge colorScheme='yellow'>Get</Badge>
      </Stack>
      <Text as='i' fontSize={'11px'} textAlign={'left'}> Se requiere un token de tipo Bearer para acceder a este endpoint.</Text>
      <Text fontSize={'14px'}> 
      Este endpoint devuelve una lista de los 10 usuarios más buscados en la plataforma, incluyendo su nombre de usuario, puntuación, un comentario generado y la cantidad de veces que han sido buscados.
      </Text>
      <Divider  m={'15px 0'}/>
      <Stack direction='row' alignItems={'center'} mb={'10px'}> 
       <Text fontSize={'12px'}>Respuesta Exitosa:</Text>  
       <Badge fontSize={'11px'} colorScheme='green'>Código: 200</Badge>
      </Stack>
      <Textarea value={`[
  {
    "userName": "usuario1",
    "score": 6.2,
    "ai_comment": "Comentario generado 1",
    "searched_count": 15
  },
  ...
]`}
            readOnly
            size='sm'
            >
       
      </Textarea>
      <Stack direction='row' alignItems={'center'}  m={'10px 0'}> 
       <Text fontSize={'12px'}>Respuestas de Error:</Text>  
       <Badge fontSize={'11px'} colorScheme='red'>Código: 500</Badge>
      </Stack>
      <Textarea value={`{ 
        error : "Mensaje de error" 
}`}
            readOnly
            size='sm'
            >
       
      </Textarea>
    </div>
        
  )
}

export default AppDocumentation

