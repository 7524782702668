import {
  Box,
  Input,
  Button,
  Spinner,
  VStack,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useToast,
  Grid,
  Textarea,
  Text,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Logo from './Logo';
import TweetPreview from './TweetPreview';
import Typewriter from 'typewriter-effect';
import './style/animatedDots.css';
import LanguageSelector from './LanguageSelector';
import HistorialItem from './HistorialItem';
import { useTranslation } from 'react-i18next';
import './core/translations/i18n';
import Joyride, {  EVENTS, STATUS } from 'react-joyride';
import ShareButton from './ShareButton';
import SocketService from './SocketService';
import apiService from './core/network/ApiService';
import TopSearched from './app/top_searched/TopSearched';
import LogoSubtitle from './app/components/LogoSubtitle';
import AppFooter from './app/components/footer/AppFooter';

function BlinkingDots() {
  return (
    <div className="blinking-dots" margin="0 auto" width="61px">
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}

const SearchComponent = () => {
  const [run, setRun] = useState(false);
  const stepIndex  = 0;

  let params = useParams();
  const { t } = useTranslation();

  const toast = useToast();
  const [borderColor, setBorderColor] = useState('gray.200');
  const [data, setData] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [customPrompt, setCustomPrompt] = useState(
    params.prompt === 'prompt' ? true : false
  );
  const [loaded, setLoaded] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [resultado, setResultado] = useState('');
  const [perfil, setPerfil] = useState('');
  const [comentario, setComentario] = useState('');
  const [paso, setPaso] = useState('');
  const [titulo, setTitulo] = useState('');
  const [tabIndex, setTabIndex] = useState(0);

  const [timeout, setTimeoutAPI] = useState('10000');
  const [reloadItems, setReloadItems] = useState(false);

  const handleChangeTimeOut = value => {
    setTimeoutAPI(value);
  };

  const steps = [
    {
      target: '.my-first-step',
      content: t('step1'),
    },
  ];

  useEffect(() => {
    setRun(true);
    SocketService.subscribeToProgress(message => {

      setTitulo('');
      setPaso(message);
    });

    return () => {
      SocketService.unsubscribeFromProgress();
    };
  }, [t]);

  function actualizarPuntuacion(usuario, nuevaPuntuacion) {
    // Obtener el array de puntuaciones de localStorage
    const puntuaciones = JSON.parse(localStorage.getItem('puntuaciones')) || [];

    // Buscar el índice del usuario en el array (no sensible a mayúsculas/minúsculas)
    const indice = puntuaciones.findIndex(p => p.usuario.toLowerCase() === usuario.toLowerCase());

    if (indice !== -1) {
      // Si el usuario existe, actualizar su puntuación
      puntuaciones[indice].puntuacion = nuevaPuntuacion;
    } else {
      // Si el usuario no existe, añadirlo al array
      puntuaciones.push({ usuario, puntuacion: nuevaPuntuacion });
    }

    // Guardar el array actualizado en localStorage
    localStorage.setItem('puntuaciones', JSON.stringify(puntuaciones));
  }

  // function reemplazarCorchetes(texto) {
  //   return texto.replace(/\[/g, 'ej:[');
  // }

  const formatInput = input => {
    const twitterProfileUrlRegex =
      /(?:http(?:s)?:\/\/)?(?:www\.)?(twitter\.com|x\.com)\/([a-zA-Z0-9_]+)/;
    const match = input.match(twitterProfileUrlRegex);

    if (match) {
      return '@' + match[2];
    } else {
      // Si no es una URL, asumir que es un nombre de usuario y añadir @ si no lo tiene
      return input.startsWith('@') ? input : '@' + input;
    }
  };

  const handleReloadItems = () => {
    setReloadItems(true);
    setTimeout(() => {
      setUsuarios(JSON.parse(localStorage.puntuaciones));
      setReloadItems(false);
    }, 500);
  };

  const handleSearch = () => {

    setPerfil(formatInput(perfil));
    setComentario('');
    setResultado(0);
    setIsProcessing(true);
    if (!perfil.trim()) {
      setBorderColor('red.500');
      toast({
        title: 'Error',
        description: t('toast1'),
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      setTimeout(() => setBorderColor('gray.200'), 3000);
      return;
    }

    var usuarioPerfil;
    if (perfil.includes('@')) {
      localStorage.setItem('perfil', perfil.substring(1));
      usuarioPerfil = perfil.substring(1);
    } else {
      localStorage.setItem('perfil', perfil.split('/').pop());
      usuarioPerfil = perfil.split('/').pop();
    }
    setTabIndex(1); // Cambia a la pestaña "Processing / Related tweets"
    setLoaded(false);
    setLoadingData(false);
    //
    var url;
    if (customPrompt) {
      url =
    'getTweetsDate?userUrl=' +
        perfil +
        '&timeout=' +
        timeout +
        '&prompt=' +
        propmtvalue;
    } else {
      url = `getTweetsDate?userUrl=${perfil}&timeout=${timeout}&userId=${
        localStorage.socketid
      }&language=${localStorage.language || 'en'}`;
    }

    apiService
      .get(url)
      .then(res => {
        console.log(res.data)
        if (res.data === 'no tweets') {
          toast({
            title: 'Info',
            description: t('toast2'),
            status: 'info',
            duration: 3000,
            isClosable: true,
          });
          setLoaded(true);
          actualizarPuntuacion(localStorage.perfil, 0.0);
          setUsuarios(JSON.parse(localStorage.puntuaciones));
          setResultado(0);
          setComentario(t('nothing'));
          setIsProcessing(false);
          return;
        }
        if (res.data === 'none') {
          toast({
            title: 'Info',
            description: t('toast3'),
            status: 'info',
            duration: 3000,
            isClosable: true,
          });
          setLoaded(true);
          actualizarPuntuacion(localStorage.perfil, 0.0);
          setUsuarios(JSON.parse(localStorage.puntuaciones));
          setResultado(Number(0));
          setComentario(t('nothing'));
          setIsProcessing(false);
          return;
        }
        const arryData = res.data.array;
        const str = res.data.responseAI.message.content;
        setData(arryData);
        setLoadingData(true);
        const regex = /<score>([\d.]+)<\/score>/;
        const regexComentario = /<recap>(.*?)<\/recap>/;
        const matchComent = str.match(regexComentario);
        const match = str.match(regex);
        if (match && match[1]) {
          const score = parseInt(match[1], 10);
          var multiplicador =
            parseInt((perfil.length * score).toString()[0]) / 10;

          const scoreFinal = score + multiplicador;
          setResultado(scoreFinal);
          actualizarPuntuacion(localStorage.perfil, scoreFinal);
        } else {
        }
        if (matchComent && matchComent[1]) {
          setComentario(matchComent[1]);
        } else {
          toast({
            title: 'Info',
            description: t('toast3'),
            status: 'info',
            duration: 3000,
            isClosable: true,
          });
        }
        setLoaded(true);
      })
      .catch(error => {
        // Maneja el error
        console.error('Ha ocurrido un error:', error);
        console.log(error.response.code )
        setLoaded(true);
        setIsProcessing(false);
        if (error.response) {
          if (error.response.data.error === 'no user') {
            setBorderColor('yellow.500');
            toast({
              title: 'Warning',
              description: t('toast4'),
              status: 'warning',
              duration: 3000,
              isClosable: true,
            });
            setTimeout(() => setBorderColor('gray.200'), 3000);
          } else {
            toast({
              title: 'Warning',
              description: t('toast6'),
              status: 'warning',
              duration: 3000,
              isClosable: true,
            });
          }

       
          // La solicitud se realizó y el servidor respondió con un código de estado
          // que cae fuera del rango de 2xx
          console.error(error.response.data);
          console.error(error.response.status);
          console.error(error.response.headers);
        } else if (error.request) {
          console.log('Debe mostrar toast request')
          toast({
            title: 'Info',
            description: t('toast5') + error.message,
            status: 'warning',
            duration: 3000,
            isClosable: true,
          });
          console.error(error.request);
        } else {
          // Algo salió mal al configurar la solicitud
          console.log('Debe mostrar toas')
          console.error('Error', error.message);
          toast({
            title: 'Warning',
            description: t('toast5') + error.message,
            status: 'warning',
            duration: 3000,
            isClosable: true,
          });
        }
        if (localStorage.puntuaciones !== undefined)
          setUsuarios(JSON.parse(localStorage.puntuaciones));
        setLoaded(true);
      });
    setResultado(0);

  };

  const handleProcessing = () => {
    setIsProcessing(false);
  };

  const handleKeyDown = e => {
    if (e.key === 'Enter') {
      setTabIndex(1);
      handleSearch();
    }
  };

  useEffect(() => {
    if (Object.keys(params).length !== 0 && !customPrompt) {
      setLoaded(false);
      setRun(false);
      setLoadingData(false);
      setIsProcessing(true);
      setTabIndex(1);
      setPerfil('@' + params.prompt);
      apiService
        .get(`getprofile/${params.prompt}`)
        .then(res => {
          localStorage.setItem('profile', params.prompt);
          const user = res.data.user;
          const tweets = res.data.tweets;

          setComentario(user.ai_comment);
          const score = Number(user.score);

          setResultado(score);
          actualizarPuntuacion(localStorage.perfil, score);
          const arryceros = new Array(150).fill(0);
          const tweetsFake = arryceros.concat(tweets);
          setData(tweetsFake);
          setIsProcessing(true);
          setLoadingData(true);
          setLoaded(true);
        }).catch(error => {
            toast({
              title: 'Warning',
              description: t('toast4'),
              status: 'warning',
              duration: 3000,
              isClosable: true,
            });  
          setLoaded(true)
          setLoadingData(true);
        });
    }

    if (localStorage.puntuaciones) {
      console.log(localStorage.puntuaciones);

      setUsuarios(JSON.parse(localStorage.puntuaciones));
    }
    console.log(usuarios);
  }, []);

  const [propmtvalue, setPromptvalue] = useState('');

  const handleChange = event => setPromptvalue(event.target.value);

  const handleChangeTab = index => {
    setTabIndex(index);
    if (localStorage.puntuaciones) {
      console.log(localStorage.puntuaciones);

      setUsuarios(JSON.parse(localStorage.puntuaciones));
    }
    console.log(usuarios);
  };

  const handleJoyrideCallback = data => {
    const {  status, type } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRun(false);
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
    }
  };

  return (
    <>
      <Joyride
        callback={handleJoyrideCallback}
        run={run}
        steps={steps}
        stepIndex={stepIndex}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      <VStack>
        <LogoSubtitle/>
       
        {loaded ? (
          <Logo targetScore={resultado} comment={comentario} />
        ) : (
          <Box
            position="relative"
            width="436px"
            height="239px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <VStack>
              <Box>
                <Spinner
                  thickness="9px"
                  speed="0.65s"
                  emptyColor="#EEE"
                  color="#000"
                  style={{
                    position: 'relative',
                    zIndex: 7,
                    width: '77px',
                    height: '77px',
                    margin: '0 auto',
                    transform: 'translate( -50%, -50% )',
                  }}
                />
              </Box>
              <Box>
                <VStack>
                  <Box>
                    <Text>{titulo}</Text>
                  </Box>
                  <Box>
                    <Typewriter
                      options={{
                        strings: paso,
                        autoStart: true,
                        loop: false,
                        delay: 10,
                      }}
                    />
                  </Box>
                </VStack>
              </Box>
            </VStack>
          </Box>
        )}

        {/* Barra de búsqueda y botón */}

        {/* <HStack>
          <Box m={'0 auto'}>
            <Input
              type="text"
              placeholder={t('placeholderPerfil')}
              value={perfil}
              className={'my-first-step'}
              borderColor={borderColor}
              // borderWidth={3}
              backgroundColor={'#FED000'}
              onChange={e => {
                setPerfil(e.target.value);
                setRun(false);
              }}
              onKeyDown={handleKeyDown}
              // w={['100%', '70%']}
            />
            <Button
              // w={['100%', 'auto']}
              color="white"
              backgroundColor="black"
              borderRadius="0"
              _hover={{ color: 'black', background: 'gray' }}
              onClick={() => {
                handleSearch();
              }}
            >
              {t('botonAnalizar')}
            </Button>
          </Box>
        </HStack> */}
            <InputGroup size='md' width={'70%'} maxWidth={'375px'}  m={'45px 0'}>
            <Input
               type="text"
               borderRadius={'0px'}
               padding={'8px 24px'}
               placeholder={t('placeholderPerfil')}
               value={perfil}
               className={'my-first-step'}
               borderColor={borderColor}
               // borderWidth={3}
               backgroundColor={'#FED000'}
               onChange={e => {
                 setPerfil(e.target.value);
                //  setRun(false);
               }}
               onKeyDown={handleKeyDown}
              />
              <InputRightElement width='4.5rem'>
              <Button borderRadius={'0px'} padding={'8px 16px'} color={'#FFF'} backgroundColor={'#BFC6CC'} h='100%' size='sm' onClick={handleSearch}>
                 {'Analizar'}
              </Button>
            </InputRightElement>
          </InputGroup>
        <Box m={'0 auto'}>
            <LanguageSelector />
          </Box>

        {customPrompt && (
          <Textarea
            w="385px"
            value={propmtvalue}
            onChange={handleChange}
            placeholder="Escribe algo aquí..."
            size="sm"
          />
        )}
        <Box p={4} width={['385px', '436px', '536px']} height="257px">
          <Tabs
            isFitted
            index={tabIndex}
            colorScheme='#000'
            onChange={index => {
              handleChangeTab(index);
            }}
          >
            <TabList>
              <Tab>{t('topSearched')}</Tab>
              <Tab>{t('history')}</Tab>
              <Tab width={isProcessing ? '85px' : '145px'}>
                {isProcessing ? (
                  <>
                    <BlinkingDots />
                  </>
                ) : (
                  t('relatedTweets')
                )}
              </Tab>
            </TabList>
            <TabPanels h={'300px'} overflowY={'auto'} className="div-scroll">
              <TabPanel>
              <Grid>
                    <TopSearched />
              </Grid>
              </TabPanel>
              <TabPanel>
                {usuarios.length === 0 ? (
                  <>
                    <Text mb={4} fontSize={'11px'} padding={'31px 31px 0 31px'}>
                      {t('explicacion')}
                    </Text>
                    <Text mb={4} fontSize={'11px'} padding={'31px 31px 0 31px'}>
                      {t('explicacion2')}
                    </Text>
                    <ShareButton
                      text="Antisemitescore.org - AntisemiteScore reads tweets &  RTs by the analyzed account, and provides a score of Antisemitism with the assistance of artificial intelligence."
                    />
                  </>
                ) : (
                  <Grid>
                    {usuarios.length !== 0 &&
                      !reloadItems &&
                      usuarios.map((usuario, index) => (
                        <HistorialItem
                          key={index}
                          usuario={usuario}
                          onDelete={handleReloadItems}
                        />
                      ))}
                  </Grid>
                )}
              </TabPanel>
              <TabPanel>
                {loadingData && (
                  <TweetPreview
                    data={data}
                    onFinish={handleProcessing}
                    targetDuration={resultado}
                  />
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
        <AppFooter  />
      </VStack>
    </>
  );
};

export default SearchComponent;
